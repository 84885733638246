@font-face {
  font-family: 'Akzidenz';
  src: url('_assets/fonts/AkzidenzGroteskPFL.eot');
  src: url('_assets/fonts/AkzidenzGroteskPFL.eot?#iefix') format('embedded-opentype'),
  url('_assets/fonts/AkzidenzGroteskPFL.woff2') format('woff2'),
  url('_assets/fonts/AkzidenzGroteskPFL.woff') format('woff'),
  url('_assets/fonts/AkzidenzGroteskPFL.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AkzidenzCondensed';
  src: url('_assets/fonts/AkzidenzGroteskLtCnPFL.eot');
  src: url('_assets/fonts/AkzidenzGroteskLtCnPFL.eot?#iefix') format('embedded-opentype'),
  url('_assets/fonts/AkzidenzGroteskLtCnPFL.woff2') format('woff2'),
  url('_assets/fonts/AkzidenzGroteskLtCnPFL.woff') format('woff'),
  url('_assets/fonts/AkzidenzGroteskLtCnPFL.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html,
body,
#app{
  height: 100%;
}

body {
  margin: 0;
  font-family: Akzidenz, sans-serif;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-attachment: fixed;
}
.background-green {
  background-image: url('./_assets/images/bg-layer-green@2x.jpg');
}
.background-white {
  background-image: url('./_assets/images/bg-blue.jpg');
}

p {
  font-family: 'AkzidenzCondensed', sans-serif;
  font-size: 32px;
  line-height: 1.2;
}

label {
  font-weight: normal;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aaa;
  font-style: italic;
  opacity: .8;
  font-weight: 300;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #aaa;
  font-style: italic;
  opacity: .8;
  font-weight: 300;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #aaa;
  font-style: italic;
  opacity: .8;
  font-weight: 300;
}


/* button loader */
.button-loader > .button-text {
    visibility: hidden;
  }
.button-loader::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #000000;
  border-right-color: #000000;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}
@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}
